"use client";

import { signOutAction } from "@/app/actions";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { createClient } from "@/utils/supabase/client";
import { Store } from "@/utils/interfaces/interfaces";

interface HeaderAuthProps {
  settings?: Partial<Store>;
}

export default function HeaderAuth({ settings }: HeaderAuthProps) {
  const [user, setUser] = useState<any>(null);
  const supabase = createClient();

  useEffect(() => {
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    getUser();
  }, []);

  return user ? (
    <div className="flex items-center gap-4">
      <span className={settings?.font_color ? `text-[${settings.font_color}]` : ''}>
        Hey, {user.email}!
      </span>
      <form action={signOutAction}>
        <Button 
          type="submit" 
          variant="outline"
          className={settings?.primary_color 
            ? `border-[${settings.primary_color}] text-[${settings.primary_color}] hover:bg-[${settings.primary_color}]/10` 
            : ''}
        >
          Sign out
        </Button>
      </form>
    </div>
  ) : (
    <div className="flex gap-2">
      <Button 
        asChild 
        size="sm" 
        variant="outline"
        className={settings?.primary_color 
          ? `border-[${settings.primary_color}] text-[${settings.primary_color}] hover:bg-[${settings.primary_color}]/10` 
          : ''}
      >
        <Link href="./sign-in">Sign in</Link>
      </Button>
      <Button 
        asChild 
        size="sm" 
        variant="default"
        className={settings?.primary_color 
          ? `bg-[${settings.primary_color}] hover:bg-[${settings.primary_color}]/90` 
          : ''}
      >
        <Link href="./sign-up">Sign up</Link>
      </Button>
    </div>
  );
}
