"use client";
import Link from 'next/link'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ShoppingCart, Settings, Package, BarChart } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Badge } from "@/components/ui/badge";
import { Store } from "@/utils/interfaces/interfaces";
import { useEffect, useState } from "react";
import { createClient } from "@/utils/supabase/client";
import HeaderAuth from './header-auth';

interface NavProps {
  domain?: string;
  cartCount?: number;
  settings?: Partial<Store>;
}

export function Nav({ domain, cartCount = 0, settings }: NavProps) {
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    async function checkOwnership() {
      if (!domain) return;
      const supabase = createClient();
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;
      
      const { data: store } = await supabase
        .from('stores')
        .select('user_id')
        .eq('domain', domain)
        .single();
        
      setIsOwner(store?.user_id === user.id);
    }
    
    checkOwnership();
  }, [domain]);

  if (!domain) return null;
  
  return (
    <nav className={`border-b relative z-50 ${settings?.background_color ? `background-color: ${settings.background_color}` : 'bg-white'}`}>
      <div className="flex items-center justify-between max-w-7xl mx-auto px-4 h-16">
        <div className="flex items-center space-x-4">
          <Link href={`/domain/${domain}/shop`}>
            <Button 
              variant="ghost" 
              className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : ''}
            >
              Shop
            </Button>
          </Link>
          
          {isOwner && (
            <>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button 
                    variant="ghost" 
                    size="sm"
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                  >
                    <Settings className="h-5 w-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent 
                  align="end"
                  className={settings?.background_color ? `background-color: ${settings.background_color}` : ''}
                >
                  <DropdownMenuItem 
                    asChild
                      className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                  >
                    <Link href={`/domain/${domain}/shop/settings`}>Edit Settings</Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem 
                    asChild
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white    '}
                  >
                    <Link href={`/domain/${domain}/shop/settings`}>Edit Landing Page</Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem 
                    asChild
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white  '}
                  >
                    <Link href={`/domain/${domain}/shop/settings/landingpage/preview`}>Preview Landing Page</Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button 
                    variant="ghost" 
                    size="sm"
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white  '}
                  >
                    <Package className="h-5 w-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent 
                  align="end"
                  className={settings?.background_color ? `background-color: ${settings.background_color}` : 'bg-white'}
                >
                  <DropdownMenuItem 
                    asChild
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white  '}
                  >
                    <Link href={`/domain/${domain}/products/create`}>Create Product</Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem 
                    asChild
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                  >
                    <Link href={`/domain/${domain}/products`}>Product Library</Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button 
                    variant="ghost" 
                    size="sm"
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                  >
                    <BarChart className="h-5 w-5" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent 
                  align="end"
                  className={settings?.background_color ? `background-color: ${settings.background_color}` : 'bg-white'}
                >
                  <DropdownMenuItem 
                    asChild
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                  >
                    <Link href={`/domain/${domain}/sales`}>
                      <BarChart className="mr-2 h-4 w-4 inline-block" />
                      Sales Dashboard
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem 
                    asChild
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                  >
                    <Link href={`/domain/${domain}/sales/analytics`}>
                      <BarChart className="mr-2 h-4 w-4 inline-block" />
                      Analytics
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem 
                    asChild
                    className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white'}
                  >
                    <Link href={`/domain/${domain}/google/ads`}>Google Ads</Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          )}
        </div>

        <div className="flex items-center space-x-4">
          <HeaderAuth settings={settings} />
          <Link href={`/domain/${domain}/cart`}>
            <Button 
              variant="ghost" 
              size="sm"
              className={settings?.font_color ? `text-[${settings.font_color}] hover:text-[${settings.font_color}]/90` : 'bg-white  '}
            >
              <ShoppingCart className="h-5 w-5" />
              {cartCount > 0 && (
                <Badge 
                  variant="destructive" 
                  className={`absolute -top-2 -right-2 h-5 w-5 flex items-center justify-center p-0 text-xs
                    ${settings?.primary_color ? `bg-[${settings.primary_color}]` : ''}`}
                >
                  {cartCount}
                </Badge>
              )}
            </Button>
          </Link>
        </div>
      </div>
    </nav>
  )
}